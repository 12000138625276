// API
import api from '../helpers/api'
import { endSpinner, startSpinner } from '../redux/reducers/loadingReducer'

export const SAVE_AWARD_DATA = 'SAVE_AWARD_DATA'
export const SAVE_BADGE_DATA = 'SAVE_BADGE_DATA'
export const SAVE_ISSUER_DATA = 'SAVE_ISSUER_DATA'

const defaultState = {
  award: {},
  badge: {},
  issuer: {}
}

// Reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_AWARD_DATA: {
      return Object.assign({}, state, {
        ...state,
        award: action.payload
      })
    }
    case SAVE_BADGE_DATA: {
      return Object.assign({}, state, {
        ...state,
        badge: action.payload
      })
    }
    case SAVE_ISSUER_DATA: {
      return Object.assign({}, state, {
        ...state,
        issuer: action.payload
      })
    }
    default:
      return state
  }
}

export const saveAwardDetails = (award) => {
  return {
    type: SAVE_AWARD_DATA,
    payload: award
  }
}

export const saveBadgeDetails = (badge) => {
  return {
    type: SAVE_BADGE_DATA,
    payload: badge
  }
}

export const saveIssuerDetails = (issuer) => {
  return {
    type: SAVE_ISSUER_DATA,
    payload: issuer
  }
}

export const validateAwardDetails = async ({ id, dispatch, successCb, errorCb }) => {
  dispatch(startSpinner())
  return await api
    .get(`assertions/${id}`)
    .then(({ data = {} } = {}) => {
      const { badgeData: badgeDetails, issuerData: issuerDetails, ...awardDetails } = data

      dispatch(saveAwardDetails(awardDetails))
      dispatch(saveBadgeDetails(badgeDetails))
      dispatch(saveIssuerDetails(issuerDetails))

      successCb && successCb()
    })
    .catch((e) => {
      console.log('Error calling validateAwardDetails: ', e)
      errorCb && errorCb()
      setTimeout(() => {
        dispatch(endSpinner())
      }, 1000)
    })
}

export const validateAwardImage = async ({ id, dispatch, successCb, errorCb }) => {
  return await api
    .get(`assertions/image/${id}`)
    .then(() => {
      successCb && successCb()
      setTimeout(() => {
        dispatch(endSpinner())
      }, 1000)
    })
    .catch((e) => {
      console.log('Error calling validateAwardImage: ', e)
      errorCb && errorCb()
    })
}
