// Modules
import { format, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'

/**
 * Formats the given date in the format 'dd. MMMM yyyy' with the German locale.
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export function formatDate(date) {
  const currentDate = parseISO(date)

  const formattedDate = format(currentDate, 'dd. MMMM yyyy', { locale: de })
  return formattedDate
}
