// Modules
import { combineReducers } from 'redux'
// Actions
import loadingReducer from './loadingReducer'
import BadgeValidator from '../../ducks/BadgeValidator.ducks'

const rootReducer = combineReducers({
  validator: BadgeValidator,
  spinner: loadingReducer
})

export default rootReducer
