/**
 * Obfuscates an email address by replacing some characters with asterisks.
 * @param {string} email - The email address to obfuscate.
 * @returns {string} - The obfuscated email address.
 */
export const maskEmail = (email) => {
  const star = '*'
  const firstTwoLetters = email.slice(0, 2)

  const lastIndexOfAtTheRate = email.lastIndexOf('@')
  const lastIndexOfDot = email.lastIndexOf('.')
  const wordAfterStar = email.slice(lastIndexOfAtTheRate + 1, lastIndexOfAtTheRate + 2)
  const domainName = email.slice(lastIndexOfDot + 1)

  const startCountBeforeAtTheRate = lastIndexOfAtTheRate - 2
  const starCountAferAtTheRate = lastIndexOfDot - lastIndexOfAtTheRate - 2

  const starsBeforeAtTheRate = star.repeat(startCountBeforeAtTheRate)
  const starsAfterAtTheRate = star.repeat(starCountAferAtTheRate)
  return `${firstTwoLetters}${starsBeforeAtTheRate}@${wordAfterStar}${starsAfterAtTheRate}.${domainName}`
}
