export const SPINNER_START = 'SPINNER_START'
export const SPINNER_END = 'SPINNER_END'
export const HIDE_HEADER = 'HIDE_HEADER'

const initialState = {
  loading: true,
  hideHeader: false
}

const spinnerReducer = (state = initialState, action) => {
  const { type } = action || {}

  switch (type) {
    case SPINNER_END: {
      return {
        ...state,
        loading: false
      }
    }
    case SPINNER_START: {
      return {
        ...state,
        loading: true
      }
    }
    case HIDE_HEADER: {
      return {
        ...state,
        hideHeader: true
      }
    }
    default:
      return state
  }
}

export const endSpinner = () => {
  return {
    type: SPINNER_END
  }
}

export const startSpinner = (props) => {
  return {
    type: SPINNER_START,
    payload: props
  }
}

export const hideHeader = () => {
  return {
    type: HIDE_HEADER
  }
}

export default spinnerReducer
