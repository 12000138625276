// API
import api from '../helpers/api'

export const trackPageViews = async ({ data }) => {
  return await api
    .post('analytics/pageviews', data)
    .then(() => {})
    .catch((e) => {
      console.log('Error calling trackPageViews: ', e)
    })
}
