// Modules
import React from 'react'
import { ToastContainer } from 'react-toastify'
// Helpers
import Router from './router/router'
// Modules
import { Spinner } from './component'
// Assets
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <>
      <Router />
      <ToastContainer
        icon={true}
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Spinner />
    </>
  )
}

export default App
