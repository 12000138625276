// Modules
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
// Assets
import loaderImg from '../../assets/image/loading-spinner.svg'

const Spinner = () => {
  const [open, setOpen] = useState(false)
  const loading = useSelector((state) => state.spinner.loading)

  React.useEffect(() => {
    setOpen(loading)
  }, [loading])

  if (!open) {
    return null
  }

  return (
    <section className="spinner-block">
      <Container fluid>
        <div className="loader">
          <div className="spinner">
            <img src={loaderImg} className="img-fluid" alt="images" />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Spinner
