// ***** start - import from package *****
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// ***** end - import from package *****

// ***** start - import from files *****
import en from './translator/en.json'
import de from './translator/de.json'
// ***** end - import from files *****

// the translations
// (tip: move them in separate JSON files and import them)
const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
}
i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'de',
    fallbackLng: 'de',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  })
export default i18n
