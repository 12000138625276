// Modules
import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route, Router as Routers } from 'react-router-dom'
// Helpers
import { history } from '../history'
import routes from './routes'

const Router = () => {
  const children = routes.map((route) => {
    return route.component ? (
      <Route key={route.name} path={route.path} exact name={route.name}>
        <route.component />
      </Route>
    ) : null
  })

  return (
    <>
      <Helmet>
        <title>IHK - Badge validieren</title>
      </Helmet>
      <div className="with-box">
        <Routers history={history}>
          <Suspense fallback={''}>
            <Switch>{children}</Switch>
          </Suspense>
        </Routers>
      </div>
    </>
  )
}

export default Router
