// Modules
import React from 'react'
// Components
const FormValidator = React.lazy(() => import('../view/FormValidator'))
const ParamsValidator = React.lazy(() => import('../view/ParamsValidator'))
import { NotFound } from '../component'

const routes = [
  {
    path: '/',
    name: 'FormValidator ',
    component: FormValidator
  },
  {
    path: '/assertion/:id',
    name: 'ParamsValidator ',
    component: ParamsValidator
  },
  {
    path: '*',
    name: 'unavailabeleRoutes',
    component: NotFound
  }
]

export default routes
